<template>
    <span>
        <b-spinner v-if="!testStatus" label="Spinning"></b-spinner>
        <div v-if="testStatus">
            <test-passage-status
                    v-if="stepStatus === STATUSES.status"
                    :status="testStatus"
                    :course="course"
                    :test="testId"
                    v-on:view-result="onViewResult"
                    v-on:process="processTest()"
                    v-on:finish="finishTest()"
            />
            <test-passage-process
                    v-if="stepStatus === STATUSES.process"
                    :status="testStatus"
                    :questions="questions"
                    :methodist-mode="methodistMode"
                    :endedAt="endedAt"
                    :user-id="userId"
                    v-on:process="processTest()"
                    v-on:finish="finishAttempt()"
                    v-on:time-is-over="finishAttempt()"
            />
            <test-passage-process
                    v-if="stepStatus === STATUSES.view"
                    :status="testStatus"
                    :questions="questions"
                    :endedAt="null"
                    :view-mode="true"
                    :user-id="userId"
                    v-on:finish-view="finishView()"
            />
        </div>
    </span>
</template>

<script>
import ApiService from "../../services/api.service";
    import TestPassageProcess from "./TestPassageProcess";
    const TestStep = {
        initial: 'initial',
        status: 'status',
        process: 'process',
        view: 'view',
    };
    import TestPassageStatus from "./TestPassageStatus";
    export default {
        name: "TestPassage",
        components: {TestPassageProcess, TestPassageStatus},
        props: {
            testId: Number,
            userId: Number,
            course: Number,
            methodistMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                testStatus: null,
                questions: [],
                endedAt: null,
                stepStatus: TestStep.status,
                STATUSES: TestStep
            }
        },
        watch: {

        },
        mounted() {
            this.loadStatus();
        },
        methods: {
            loadStatus() {
                const params = {
                    userId: this.userId
                };
                this.stepStatus = TestStep.initial;
                ApiService
                    .get(
                        `tests/${this.testId}/pass-status`,
                        {
                            params
                        }
                    )
                    .then(res => {
                        this.testStatus = res.data;
                        this.stepStatus = TestStep.status;
                        if (this.testStatus.testInProcess) {
                            this.processTest();
                        }
                    });
            },
            processTest() {
                const params = {
                    userId: this.userId
                };

                ApiService
                    .get(`tests/${this.testId}/pass-process`, {params})
                    .then(res => {
                        this.endedAt = res.data.endedAt * 1000;
                        this.questions = res.data.questions;
                        this.stepStatus = TestStep.process;
                    });
            },
            finishAttempt() {
                const params = {
                    userId: this.userId
                };

                ApiService
                    .get(`tests/${this.testId}/pass-process-finish`, {params})
                    .then(() => {
                        this.stepStatus = TestStep.status;
                        this.loadStatus();
                    });
            },
            finishView() {
                this.stepStatus = TestStep.status
            },
            finishTest() {
                const params = {
                  userId: this.userId
                };

                ApiService
                    .get(`tests/${this.testId}/pass-finish`, {params})
                    .then(() => {
                        this.stepStatus = TestStep.status;
                        this.loadStatus();
                    });
            },
            onViewResult(attemptId) {
                const params = {
                  userId: this.userId
                };

                ApiService
                    .get(`tests/${this.testId}/result-view/${attemptId}`, {params})
                    .then(res => {
                        this.questions = res.data.questions;
                        this.stepStatus = TestStep.view;
                    });
            }
        }
    }
</script>

<style scoped>

</style>
