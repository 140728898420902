<template>
    <div></div>
</template>

<script>
    import AnswerStructure from "../model/AnswerStructure";
    export default {
        name: "QuestionBase",
        props: ['id', 'task', 'value'],
        data() {
            return {
                givenAnswer: new AnswerStructure()
            }
        },
        mounted() {
            this.givenAnswer = this.value
        },
        methods: {
            onChange (val) {
                this.$emit('input', val)
            }
        },
        watch: {
            givenAnswer (val) {
                this.onChange(val)
            }
        }
    }
</script>

<style scoped></style>
