<template>
    <div class="table-responsive">
        <table class="table table-condensed">
            <thead>
            <th>№</th>
            <th v-for="(column, k) in task.columns" :key="k">{{ column }}</th>
            <th>Ответ не дан</th>
            </thead>
            <tbody>
            <tr v-for="(row, r) in task.cells" :key="r">
                <td>{{ r + 1 }}</td>
                <td v-for="(column, k) in task.columns" :key="k">
                    <div v-if="row[k].isStaticText" v-html="row[k].text"></div>
                    <div v-if="!row[k].isStaticText">
                        <p-radio class="p-default p-round p-bigger"
                                 color="primary"
                                 v-bind:name="'match_radio_' + id + '_row_' + r"
                                 v-bind:value="k"
                                 style="font-size: 200%"
                                 v-model="givenAnswer.grid[r]">
                        </p-radio>
                    </div>
                </td>
                <td>
                    <p-radio class="p-default p-round p-bigger"
                             v-bind:name="'match_radio_' + id + '_row_' + r"
                             v-bind:value="-1"
                             style="font-size: 200%"
                             selected
                             v-model="givenAnswer.grid[r]">
                    </p-radio>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
    import QuestionBase from "./QuestionBase";

    export default {
        name: "QuestionMatchRadio",
        extends: QuestionBase,
        mounted () {
            if (this.task && Array.isArray(this.task.cells) && this.givenAnswer && Array.isArray(this.givenAnswer.grid) && this.givenAnswer.grid.length === 0) {
                for (let i = 0; i < this.task.cells.length; i++) {
                    this.givenAnswer.grid.push(-1)
                }
            }
        }
    }
</script>

<style scoped>

</style>
