export class TaskOptions {
    constructor() {
        this.restrictMax = null;
        this.pools = [];
        this.hasWeight = false;
    }
}
export default class TaskStructure {
    constructor() {
        this.choice = [];
        this.weights = [];
        this.choices = [];
        this.options = new TaskOptions();
        this.columns = [];
        this.cells = [];
        this.union = true;
    }
}
