<template>
    <ibox>
        <ibox-title>
            Варианты ответа:
        </ibox-title>
        <ibox-content>
            <div>
                <p></p>
                <form class="form-inline">
                    <div class="form-group">
                        <b-form-group label="Сейчас во всех колонках">
                            <b-form-select id="constructor-union-choice"
                                    title="ds"
                                    v-model="task.union">
                                <b-form-select-option :value="true" selected>общий список ответов</b-form-select-option>
                                <b-form-select-option :value="false">отдельные списки</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </form>
            </div>
            <table class="constructor-match-t table table-condensed"
                   style="table-layout: fixed;">
                <thead>
                <th width="50">
                    Заголовки<br/>колонок:
                </th>
                <th width="50"
                    tabindex="-1">
                    <button class="btn btn-light"
                            style="width: 100%"
                            v-if="task.cells.length === 0"
                            title="Добавить колонку (клавиша End)"
                            @click="addColumn()">
                        <i class="icofont icofont-ui-add"></i><span v-if="task.columns.length > 0"> &nbsp;</span>
                    </button>
                </th>
                <th tabindex="-1" v-for="(column, k) in task.columns" :key="k">
                    <input type="text"
                           :id="'con_' + id +'_colhead_' + k"
                           v-model="task.columns[k]"
                           class="form-control"
                           @keyup.end="addColumn($event, true)"
                           @keyup.enter="addRow()"
                    />
                    <div style="width: 100%; text-align: right">
                        <button class="btn btn-light"
                                style="font-size: 70%; margin-top: 2px !important; color: darkred;"
                                tabindex="-1"
                                @click="deleteColumn(k)">
                            <i class="icofont icofont-delete" style="font-size: 120%;"></i> удалить колонку
                        </button>
                    </div>
                </th>
                </thead>
                <tbody>
                <tr v-for="(row, r) in task.cells" :key="r">
                    <td>
                        <div style="width: 100%; text-align: right">
                            <button class="btn btn-light"
                                    style="font-size: 70%; margin-top: 2px !important; color: darkred;"
                                    tabindex="-1"
                                    @click="deleteRow(r)">
                                <i class="fa fa-trash" style="font-size: 120%;"></i> удалить<br/>строку
                            </button>
                        </div>
                    </td>
                    <td></td>
                    <td v-for="(column, k) in task.columns" :key="k">
                        <div class="form-group">
                            <label :for="'is_static_col' + k + '_row' + r">Статический текст?</label>
                            <input type="checkbox"
                                   :id="'is_static_col' + k + '_row' + r"
                                   :name="'is_static_col' + k + '_row' + r"
                                   v-model="task.cells[r][k].isStaticText"
                            />
                            <div class="constructor-explain">
                                <div v-if="task.cells[r][k].isStaticText">
                                    <span>статический текст задания</span><br/>
                                    <valid-summernote
                                            v-model="task.cells[r][k].text"
                                    ></valid-summernote>
                                </div>
                                <div v-if="!task.cells[r][k].isStaticText">
                                    <valid-summernote v-model="task.cells[r][k].text"></valid-summernote>
                                    <div v-if="task.union">
                                        <span>выбор ответа из общего списка</span><br/>
                                        <table style="font-size: 80%; width: 100%">
                                            <thead>
                                            <th></th>
                                            <th>верно?</th>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v, key) in task.choice" :key="key">
                                                <td class="choose-entity"
                                                    style="font-size: 120%;">
                                                    {{ key + 1 }}. {{ v }}
                                                </td>
                                                <td class="right-answer"
                                                    style="text-align: center;">
                                                    <input v-if="answer" type="radio"
                                                           :id="'right_' + id + '_' + '_row_' + r + '_col_' + k + '_' + key"
                                                           :name="'right_' + id + '_row_' + r + '_col_' + k"
                                                           :value="key"
                                                           v-model="answer.grid[r][k]"
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-if="!task.union">
                                        <span>выбор ответа из списка колонки</span>
                                        <table style="font-size: 80%; width: 100%">
                                            <thead>
                                            <th></th>
                                            <th>верно?</th>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v, key) in task.choices[k]" :key="key">
                                                <td class="choose-entity"
                                                    style="font-size: 120%;">
                                                    {{ key + 1 }}. {{ v }}
                                                </td>
                                                <td class="right-answer"
                                                    style="text-align: center;">
                                                    <input type="radio"
                                                           :id="'right_' + id + '_' + '_row_' + r + '_col_' + k + '_' + key"
                                                           :name="'right_' + id + '_row_' + r + '_col_' + k"
                                                           :value="key"
                                                           v-model="answer.grid[r][k]"
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <valid-summernote v-model="task.cells[r][k].text2"
                                    ></valid-summernote>
                                </div>
                            </div>

                        </div>
                    </td>
                </tr>
                <tr>
                    <td :colspan="2 + task.columns.length"
                        style="text-align: center">
                        <button class="btn btn-link"
                                type="button"
                                @click="addRow()"
                                style="width: 100%;
                  font-size: 140%;">
                            <i class="fa fa-plus"></i> Добавить строку (Enter)
                        </button>
                        <br/><br/>
                    </td>
                </tr>
                <tr>
                    <td :colspan="2 + task.columns.length" style="text-align: center; font-size: 110%; font-weight: bold">Редактировать список выбора ответов</td>
                </tr>
                <tr v-if="task.union">
                    <td :colspan="2 + task.columns.length">
                        <span>Единый сисок:</span>
                        <table class="table table-condensed" style="width: 100%; font-size: 90%">
                            <tbody>
                            <tr v-for="(v, key) in task.choice" :key="key">
                                <td class="choose-entity" style="font-size: 120%;">
                                    <div style="width: 100%">{{ key + 1 }}. {{ v }}</div>
                                    <br/>
                                    <div style="width: 100%">
                  <textarea
                          style="width: 100%"
                          v-if="choiceEditMode[key]"
                          v-model="task.choice[key]">
                  </textarea>
                                    </div>
                                </td>
                                <td class="edit-entity">
                                    <button class="btn"
                                            type="button"
                                            :class="{'btn-light': !choiceEditMode[key], 'btn-success': choiceEditMode[key]}"
                                            style="font-size: 80%"
                                            title="Редактировать вариант ответа"
                                            @click="toggleChoice(key)">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                </td>
                                <td class="delete-entity" style="text-align: right">
                                    <button class="btn btn-light" style="font-size: 80%"
                                            title="Удалить вариант ответа"
                                            type="button"
                                            @click="deleteChoice(key)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr class="constructor-task-add">
                                <td style="padding: 5px;">
                                    <input class="form-control" type="text" @keyup.enter="addChoice()" v-model="newEntity"/> &nbsp;
                                    <button type="button" class="btn btn-link" @click="addChoice()"><i class="fa fa-plus"></i> Добавить вариант ответа (Enter)</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr v-if="!task.union">
                    <td></td>
                    <td></td>
                    <td v-for="(сolumn, k) in task.columns" :key="k">
                        <span>Cписок для &laquo;{{ сolumn }}&raquo;:</span>
                        <table style="font-size: 80%">
                            <tbody>
                            <tr v-for="(v, key) in task.choices[k]" :key="key">
                                <td>
                                    {{ key + 1 }}. {{ v }}
                                </td>
                                <td class="delete-entity">
                                    <button class="btn btn-light" style="font-size: 70%"
                                            title="Удалить вариант ответа"
                                            @click="deleteChoiceFromChoices(k)">
                                        <i class="icofont icofont-ui-delete"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <input type="text" @keyup.enter="addChoiceToChoices(k)" v-model="newEntities[k]"/> &nbsp;<br/>
                                    <button class="btn btn-link" @click="addChoiceToChoices(k)" style="font-size: 90%"><i class="icofont icofont-ui-add"></i> (Enter)</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </ibox-content>
    </ibox>

</template>

<script>
    import Ibox from "../../Ibox/Ibox";
    import IboxContent from "../../Ibox/IboxContent";
    import IboxTitle from "../../Ibox/IboxTitle";
    import ConstructorBase from "../ConstructorBase";
    import ValidSummernote from "../../Validation/ValidSummernote";
    export default {
        name: "ConstructorMatch",
        extends: ConstructorBase,
        components: {ValidSummernote, IboxTitle, IboxContent, Ibox},
        data() {
            return {
                newEntities: []
            }
        },
        mounted () {
            for (let i = 0; i < this.task.cells.length; i++) {
                if (this.answer && !this.answer.grid[i]) {
                    this.answer.grid.push([])
                }
            }
        },
        methods: {
            addColumn () {
                if (this.task.cells.length === 0) {
                    this.task.columns.push('')
                    this.task.choices.push([])
                    this.newEntities.push('')
                }
            },

            addRow () {
                let row = []
                let answerRow = []
                for (let i = 0; i < this.task.columns.length; i++) {
                    row.push({ isStaticText: true, text: '', text2: '' })
                    answerRow.push('')
                }
                this.task.cells.push(row)
                this.answer.grid.push(answerRow)
            },

            deleteRow (key) {
                if (confirm('Вы действительно хотите удалить строку?')) {
                    this.task.cells.splice(key, 1)
                }
            },

            deleteColumn (key) {
                if (confirm('Вы действительно хотите удалить колонку?')) {
                    for (let i = 0; i < this.task.cells.length; i++) {
                        this.task.cells[i].splice(key, 1)
                    }
                    this.task.columns.splice(key, 1)
                }
            },

            addChoiceToChoices (col) {
                if (this.task && this.task.choices[col]) {
                    this.task.choices[col].push(this.newEntities[col])
                    this.newEntities[col] = ''
                    this.$emit('input', this.task)
                }
            },

            deleteChoiceFromChoices (col, key) {
                if (confirm('Удалить вариант ответа?')) {
                    if (this.task && this.task.choices[col]) {
                        this.task.choices[col].splice(key, 1)
                        this.$emit('input', this.task)
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
