<template>
    <span v-if="prettyTime">{{ prettyTime }}</span>
</template>

<script>
    function pad(n) {
        return n < 10 ? '0' + n : n
    }
    export default {
        name: "TestPassageTimer",
        props: {
            tillTime: Number,
        },
        data() {
            return {
                timer: null,
                prettyTime: null
            }
        },
        mounted() {
            this.startTimer()
        },
        destroyed() {
            this.stopTimer()
        },
        methods: {
            startTimer() {
                this.timer = setInterval(() => this.processInterval(), 500)
            },
            stopTimer() {
                if (!this.timer) {
                    return;
                }

                clearTimeout(this.timer);
            },
            processInterval() {
                const now = new Date().getTime();
                const toDate = new Date(this.tillTime);

                const distance = toDate - now;

                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) + hours * 60;
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (0 > minutes) {
                    this.prettyTime = '00:00';
                    this.stopTimer();
                    this.$emit('ended');
                    return;
                }

                this.prettyTime = `${pad(minutes)}:${pad(seconds)}`
            }
        },
        watch: {
            currentTime(time) {
                if (time === 0) {
                    this.stopTimer()
                }
            }
        },
    }
</script>

<style scoped>

</style>
