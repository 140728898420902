<template>
    <span>
        <b-form-group
                :label="`${index}.)`"
                invalid-feedback="Name is required"
        >
            <b-form-input
                    v-model="answer.name"
                    required
            ></b-form-input>
        </b-form-group>
        <b-form-checkbox
                v-if="canBeCorrect"
                v-model="answer.isCorrect"
                :value="true"
                :unchecked-value="false"
        >
              Это правильный ответ
            </b-form-checkbox>

        <b-button v-if="allowDelete" size="sm" variant="danger" @click="$emit('delete')">Удалить</b-button>
    </span>
</template>

<script>
    export default {
        name: "TestFormQuestionAnswer",
        props: {
            index: Number,
            allowDelete: {
                type: Boolean,
                default: true
            },
            canBeCorrect: {
                type: Boolean,
                default: true
            },
            answer: Object
        }
    }
</script>

<style scoped>

</style>
