<template>
    <span>
        <div class="mt-3">
            <b-button v-if="allowDelete" size="sm" variant="danger" @click="$emit('delete')">Удалить вопрос</b-button>
        </div>
        <b-form-group
                :label="'Вопрос #' + index"
                invalid-feedback="Name is required"
        >
          <summernote placeholder="Введите вопрос" v-model="question.name"/>
        </b-form-group>
        <b-form-group
            v-if="allowCodifier"
            label="Компетенции"
            label-for="type-input"
        >
            <multiselect
                    v-model="question.codifiers"
                    :multiple="true"
                    :searchable="true"
                    :options="codifiers.map(c => c.id)"
                    :custom-label="prepareCodifiers"
                    :loading="isCodifiersLoading"
                    @search-change="findCodifiers"
            ></multiselect>
        </b-form-group>
        <b-form-group label="Тип вопроса">
            <multiselect
                    v-model="question.type"
                    :options="Object.keys(TYPE_ALLOWED)"
                    :custom-label="opt => $t(`type.${opt}`)"
                    @input="handleChangeType($event)"
            />
        </b-form-group>
        <div v-if="question.type === TYPE_ALLOWED.matchRadio">
          <constructor-match-radio
              :id="question.id"
              v-model="question.match.task"
              :answerValue="question.match.answer"
              @answer="question.match.answer = $event"
          ></constructor-match-radio>
        </div>
        <div v-else-if="withAnswers">
            <div v-if="question.answers.length > 0">Ответы:</div>
            <div v-for="(answer, index) in question.answers" v-bind:key="index">
                <test-form-question-answer
                        v-bind:answer.sync="answer"
                        v-bind:index="index + 1"
                        v-bind:can-be-correct="canBeCorrect(answer)"
                        v-on:delete="deleteAnswer(index)"
                />
                <hr>
            </div>
            <b-button variant="outline-primary" @click="addAnswer()">Добавить ответ</b-button>
        </div>
    </span>
</template>

<script>
    import TestFormQuestionAnswer from "./TestFormQuestionAnswer";
    import Summernote from "../Summernote";
    import ConstructorMatchRadio from "../TestConstructor/types/ConstructorMatchRadio";
    import Question from "../TestConstructor/model/Question";
    import ApiService from "../../services/api.service";
    export const TYPE_ANSWER = {
        simple: 'simple',
        multiple: 'multiple',
        typed: 'typed',
        matchRadio: 'matchRadio'
    };
    const emitChanges = function () {
        this.$emit('update', {...this.question})
    };

    export default {
        name: "TestFormQuestion",
        components: {ConstructorMatchRadio, Summernote, TestFormQuestionAnswer},
        props: {
            index: Number,
            allowDelete: {
                type: Boolean,
                default: true
            },
            allowCodifier: {
                type: Boolean,
                default: true
            },
            questionPreset: Object
        },
        mounted() {
            if (this.allowCodifier) {
                // @todo preload limit?
                this.findCodifiers();
            }
        },
        data() {
            let question = this.questionPreset;

            return {
                isCodifiersLoading: false,
                TYPE_ALLOWED: TYPE_ANSWER,
                codifiers: [],
                question: question ? {
                    codifiers: [],
                    answers: [],
                    ...question
                } : {}
            };
        },
        computed: {
            withAnswers: function () {
                return this.question.type && this.question.type !== TYPE_ANSWER.typed && this.question.type !== TYPE_ANSWER.matchRadio;
            }
        },
        watch: {
            submitting(val) {
                if (val) {
                    this.handleSubmit();
                }
            },
            allowCodifier(val) {
                if (val && this.codifiers.length === 0) {
                    this.findCodifiers();
                }
            },
            "question.name": emitChanges,
            "question.type": emitChanges,
            "question.codifiers": emitChanges,
            "question.answers": emitChanges,
        },
        methods: {
            canBeCorrect(answer) {
                if (answer.isCorrect) {
                    return true;
                }

                if (this.question.type === TYPE_ANSWER.simple) {
                    return !this.question.answers.some(a => a.isCorrect);
                }

                return true;
            },
            addAnswer() {
                if (!this.question.answers) {
                    this.question.answers = [];
                }

                this.question.answers.push({name: '', isCorrect: false});
            },
            deleteAnswer(index) {
                this.question.answers.splice(index, 1);
            },
            handleChangeType(type) {
                if (type === TYPE_ANSWER.typed) {
                    this.question.answers = [];
                }
                if(type === TYPE_ANSWER.matchRadio) {
                  this.question.match = new Question();
                }
            },
            findCodifiers(query) {
                this.isCodifiersLoading = true;
                ApiService.get(`codifiers/?name=${query}`).then(res => {
                    this.isCodifiersLoading = false;
                    this.codifiers = res.data;
                });
            },
            prepareCodifiers(opt) {
                const codifier = this.codifiers.find(x => x.id == opt);
                return codifier ? codifier.title : undefined;
            }
        }
    }
</script>

<style scoped>

</style>

<i18n>
{
    "ru": {
        "type.simple": "Единственный выбор",
        "type.multiple": "Множественный выбор",
        "type.typed": "Набор пользователем",
        "type.matchRadio": "Соответсвие с радиокнопками"
    }
}
</i18n>
