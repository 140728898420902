import TaskStructure from './TaskStructure';
import AnswerStructure from './AnswerStructure';
export var QuestionStatus;
(function (QuestionStatus) {
    QuestionStatus[QuestionStatus["wait"] = 1] = "wait";
    QuestionStatus[QuestionStatus["pending"] = 2] = "pending";
    QuestionStatus[QuestionStatus["answered"] = 3] = "answered";
    QuestionStatus[QuestionStatus["postponed"] = 4] = "postponed";
    QuestionStatus[QuestionStatus["correct"] = 5] = "correct";
    QuestionStatus[QuestionStatus["wrong"] = 6] = "wrong";
})(QuestionStatus || (QuestionStatus = {}));
export default class Question {
    constructor() {
        this.id = '';
        this.headerText = '';
        this.variant = '';
        this.status = '';
        this.task = new TaskStructure();
        this.answer = new AnswerStructure();
        this.givenAnswer = new AnswerStructure();
        this.givenAnswerId = '';
    }
}
