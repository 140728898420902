<template>
    <div v-if="status" class="animated fadeIn">
        <b-modal
                v-if="!viewMode"
                id="confirm-finish"
                ref="modal"
                button-size="sm"
                size="lg"
                title="Подтвердите завершение теста"
                @ok="finishAttempt()"
                ok-title="Да"
                cancel-title="Нет"
        >
            Вы действительно хотите завершить тест?
        </b-modal>
        <div class="text-left">
            <div v-if="!methodistMode && endedAt" class="bg-danger text-center list-group-item">
                <test-passage-timer style="font-size: 2vw;"
                    :till-time="endedAt"
                    v-on:ended="onTimeIsOver()"
                />
            </div>
            <b-tabs v-model="tabIndex" content-class="mt-3" pills card vertical>
                <b-tab v-for="(questionData, index) in questions" :key="index" :title="`Вопрос ${index + 1}`">
                    <test-passage-process-question
                            :data="questionData"
                            :readOnly="viewMode"
                            v-on:update-answer="onUpdateAnswer"
                    />
                </b-tab>
            </b-tabs>
        </div>
        <hr class="hr-line-dashed" />
        <b-row>
            <b-col>
                <b-button
                    v-if="tabIndex + 1 < questions.length"
                    @click="nextQuestion()"
                    variant="primary">
                    Следующий вопрос <i class="fa fa-arrow-right"></i>
                </b-button>
            </b-col>
            <b-col>
                <b-button
                    v-if="!viewMode"
                    variant="danger"
                    @click="$bvModal.show('confirm-finish')"
                >
                    <i class="fa fa-stop"></i> Завершить тест
                </b-button>
                <b-button
                    v-if="viewMode"
                    variant="secondary"
                    @click="$emit('finish-view')"
                >
                    Завершить просмотр
                </b-button>
            </b-col>
        </b-row>
        <br/>
    </div>
</template>

<script>
    // import Vue from "vue";
    import ApiService from "../../services/api.service";
    import TestPassageTimer from "./TestPassageTimer";
    import TestPassageProcessQuestion from "./TestPassageProcessQuestion";
    export default {
        name: "TestPassageProcess",
        components: {TestPassageProcessQuestion, TestPassageTimer},
        props: {
            status: Object,
            userId: Number,
            endedAt: Number,
            questions: Array,
            methodistMode: {
                type: Boolean,
                default: false
            },
            viewMode: {
                type: Boolean,
                default: false
            },
        },
        data() {
          return {
              tabIndex: 0
          }
        },
        methods: {
            onTimeIsOver() {
                if (this.viewMode) {
                    return;
                }
                this.$emit('time-is-over');
            },
            finishAttempt() {
                if (this.viewMode) {
                    return;
                }

                this.$emit('finish')
            },
            nextQuestion() {
                ++this.tabIndex;
            },
            onUpdateAnswer(eventData) {
                if (this.viewMode) {
                    return;
                }

                const questionId = eventData.question;
                const values = eventData.values;
                const match = eventData.match;

                ApiService
                    .postJSON(
                        `tests/${this.status.id}/pass-question-update`,
                        {
                            question: questionId,
                            values,
                            userId: this.userId || null,
                            match: match
                        }
                    );
            }
        }
    }
</script>

<style scoped>

</style>
