<template>
  <table v-if="task" class="constructor-match-t table table-condensed"
         style="table-layout: fixed;">
    <thead>
    <th width="50">
      Заголовки<br/>колонок:
    </th>
    <th width="50"
        tabindex="-1">
      <button class="btn btn-light btn-success"
              style="width: 100%"
              type="button"
              v-if="task.cells.length === 0"
              @click="addColumn()">
        <i class="fa fa-plus"></i>&nbsp;
        Добавить колонку
      </button>
    </th>
    <th tabindex="-1" v-for="(column, k) in task.columns" width="50" :key="k">
      <input type="text"
             :id="'con_' + id +'_colhead_' + k"
             v-model="task.columns[k]"
             class="form-control"
             @keyup.end="addColumn($event, true)"
             @keyup.enter="addRow()"
      />
      <div style="width: 100%; text-align: right">
        <button class="btn btn-danger"
                style="font-size: 70%; margin-top: 2px !important;"
                tabindex="-1"
                @click="deleteColumn(k)">
          <i class="fa fa-trash" style="font-size: 120%;"></i> удалить
        </button>
      </div>
    </th>
    </thead>
    <tbody>
    <tr v-for="(row, r) in task.cells" :key="r">
      <td width="20">
        <div style="width: 100%;">
          <button class="btn btn-danger"
                  style="font-size: 70%; margin-top: 2px !important;"
                  tabindex="-1"
                  @click="deleteRow(r)">
            <i class="fa fa-trash" style="font-size: 120%;"></i> удалить
          </button>
        </div>
      </td>
      <td></td>
      <td v-for="(column, k) in task.columns" :key="k">
        <div class="form-group">
          <label :for="'is_static_col' + k + '_row' + r"></label>
          <p-input type="checkbox"
                   :id="'is_static_col' + k + '_row' + r"
                   :name="'is_static_col' + k + '_row' + r"
                   v-model="task.cells[r][k].isStaticText"
          >Текст вопроса?</p-input>
          <div class="constructor-explain">
            <div v-if="task.cells[r][k].isStaticText">
              <span>статический текст задания</span><br/>
              <valid-summernote
                  v-model="task.cells[r][k].text"
                  @keyup.enter="addRow()"></valid-summernote>
            </div>
            <div v-if="!task.cells[r][k].isStaticText"
                 style="text-align: center; padding: 10px; margin-top: 5px">
              <p-radio class="p-default p-round p-bigger"
                       v-bind:name="'match_radio_' + id + '_row_' + r"
                       v-bind:value="k"
                       style="font-size: 200%"
                       v-model="answer.grid[r]">
              </p-radio>
            </div>
          </div>

        </div>
      </td>
    </tr>
    <tr>
      <td :colspan="2 + task.columns.length" style="text-align: center">
        <button type="button" class="btn btn-link" @click="addRow()" style="width: 100%; font-size: 140%;">
          <i class="fa fa-plus"></i> Добавить строку
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
    import ConstructorMatch from "./ConstructorMatch";
    export default {
        name: "ConstructorMatchRadio",
        extends: ConstructorMatch,
        methods: {
            addRow () {
                let row = []
                let answerRow = []
                for (let i = 0; i < this.task.columns.length; i++) {
                    row.push({ isStaticText: i === 0, text: '', text2: '' })
                    answerRow.push(false)
                }
                this.task.cells.push(row)
                this.answer.grid.push(answerRow)
            }
        }
    }
</script>

<style scoped>

</style>
