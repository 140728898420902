<template>
    <div></div>
</template>

<script>
    import TaskStructure, {TaskOptions} from "./model/TaskStructure";
    import {Vue} from "vue-property-decorator";
    import AnswerStructure from "./model/AnswerStructure";
    import PrettyCheckbox from 'pretty-checkbox-vue'
    import 'pretty-checkbox/src/pretty-checkbox.scss'
    Vue.use(PrettyCheckbox)
    export default {
        name: "ConstructorBase",
        props: ['id', 'answerValue', 'value'],
        data() {
            return {
                task: this.value,
                rightAnswer: null,
                choiceEditMode: [],
                newEntity: '',
                answer: null
            }
        },
        methods: {
            normalizeTask(value) {
                let task = new TaskStructure();
                Vue.set(task, 'options', new TaskOptions());
                if (this.value) {
                    task = Object.assign(task, value);
                    if (this.value.options && !(Array.isArray(this.value.options) && this.value.options.length === 0)) {
                        task.options = Object.assign(task.options, value.options);
                    }
                }
                return task;
            },
            normalizeAnswer(value) {
                let a = new AnswerStructure();
                if (this.value) {
                    a = Object.assign(a, value);
                }
                return a;
            },
            addChoice() {
                if (this.task && this.task.choice) {
                    this.task.choice.push(this.newEntity);
                    this.newEntity = '';
                    this.giveTask();
                }
            },
            onAnswerChange() {
                this.giveAnswer();
            },
            giveAnswer() {
                this.$emit('answer', this.answer);
            },
            giveTask() {
                this.$emit('input', this.task);
            },
            toggleChoice(key) {
                let clonedChoiceEditMode = this.choiceEditMode.slice(0);
                clonedChoiceEditMode[key] = !clonedChoiceEditMode[key];
                this.choiceEditMode = clonedChoiceEditMode;
            },
            deleteChoice(key) {
                if (confirm('Удалить вариант ответа?')) {
                    if (this.task && this.task.choice) {
                        this.task.choice.splice(key, 1);
                        this.giveTask();
                    }
                }
            }
        },
        mounted() {
            this.task = this.normalizeTask(this.value);
            this.answer = this.normalizeAnswer(this.answerValue);
            this.task.choice.forEach(() => this.choiceEditMode.push(false));
        },
    }
</script>

<style scoped>

</style>
