<template>
    <validation-provider :name="name" :vid="vid" :rules="rules" v-slot="validationContext">
        <b-form-group :label="label">
            <b-form-textarea
                    @input="updateValue"
                    :value="value"
                    :disabled="disabled"
                    :state="valid(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
    export default {
        name: "ValidTextarea",
        props: ['rules', 'name', 'value', 'label', 'vid', 'disabled'],
        methods: {
            updateValue: function (value) {
                this.$emit('input', value)
            }
        }
    }
</script>

<style scoped>

</style>
